<script>
import ModalWindow from "@/components/Global/ModalWindow.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import Swal from "sweetalert2";
import forIn from "lodash/forIn";
import BtnSecondary from "@/components/Global/BtnSecondary.vue";
import PlusSign from "@/components/Icons/PlusSign.vue";
import CText from "@/components/Form/CText.vue";

export default {
    components: {
        CText,
        PlusSign,
        BtnSecondary,
        BtnPrimary,
        ModalWindow,
    },
    data() {
        return {
            showRedeemModal: false,
            form: {},
        };
    },

    methods: {
        redeem() {
            const uri = route("student.redeem-code");

            axios
                .post(uri, this.form)
                .then(({ data }) => {
                    this.showRedeemModal = false;
                    Swal.fire({
                        title: "Redeem Code",
                        html: "This code  has been added to your account!",
                        icon: "info",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        this.$emit("redeemed", data);
                        this.form.code = "";
                    });
                })
                .catch((errors) => {
                    this.showRedeemModal = false;
                    const options = {
                        title: "Error",
                        icon: "error",
                    };

                    if (errors.response?.status === 422) {
                        options.html = `<ul class="text-left">${_.reduce(
                            errors.response.data.errors,
                            (html, error) => {
                                let content = html;
                                forIn(error, (item, key) => {
                                    content += `<li>${item}</li>`;
                                });

                                return content;
                            },
                            "",
                        )}</ul>`;
                    } else {
                        options.text =
                            "There was an unexpected error. If this continues, please contact the site administrator.";
                    }

                    Swal.fire(options);
                });
        },
    },
};
</script>

<template>
    <div class="inline-block">
        <BtnPrimary
            class=""
            @click="showRedeemModal = true"
        >
            <template #icon>
                <PlusSign />
            </template>
            Redeem Code
        </BtnPrimary>

        <Teleport
            to="body"
            v-if="showRedeemModal"
        >
            <ModalWindow
                v-model:show="showRedeemModal"
                header-classes="modal-header p-5 text-center"
                footer-classes="modal-footer border0-t px-5 pb-5 text-center"
            >
                <template #header>
                    <h5 class="text-2xl uppercase">Redeem Code</h5>
                </template>

                <div class="w-96">
                    <CText
                        type="text"
                        placeholder="Enter Code..."
                        id="new_code"
                        v-model="form.code"
                    />
                </div>

                <template #footer>
                    <div class="justify-center gap-1 md:flex">
                        <BtnSecondary
                            @click="showRedeemModal = false"
                            class="mr-1 mt-2 md:mt-0 md:w-24"
                        >
                            Close
                        </BtnSecondary>

                        <BtnPrimary
                            class="md:w-24"
                            @click="redeem"
                        >
                            Claim
                        </BtnPrimary>
                    </div>
                </template>
            </ModalWindow>
        </Teleport>
    </div>
</template>
