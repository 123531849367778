<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const saved = ref<boolean>(false);

const saving = computed(() => store.getters.saving);
const isSaving = computed(() => saving.value && !saved.value);

const text = computed(() => (saved.value ? "Progress Saved" : "Saving Progress"));
const icon = computed(() => (saved.value ? "check" : "redo-alt"));

watch(saving, (newValue, oldValue) => {
    saved.value = !!(!newValue && oldValue);
});

watch(saved, (newValue) => {
    if (newValue) {
        setTimeout(() => {
            saved.value = false;
        }, 1000);
    }
});
</script>

<template>
    <div
        id="taking-test-saving-progress"
        class="absolute mr-5 flex h-50p w--5 flex-row-reverse items-center bg-white pr-5 text-gray-500"
        v-show="saving || saved"
    >
        <FontAwesomeIcon
            :icon="icon"
            :spin="isSaving"
            flip="horizontal"
            size="lg"
            class="mx-3"
            v-if="isSaving"
        />

        <FontAwesomeIcon
            :icon="icon"
            size="lg"
            class="mx-3"
            v-else
        />

        {{ text }}
    </div>
</template>
