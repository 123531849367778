<script>
import { mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

export default {
    components: { FontAwesomeIcon, FontAwesomeLayers },
    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            _graded: "graded",
            _isQuestionCorrect: "isQuestionCorrect",
            _isQuestionIncorrect: "isQuestionIncorrect",
            _isQuestionPartiallyCorrect: "isQuestionPartiallyCorrect",
            _isSample: "isSample",
            _navigating: "navigating",
            _submittedAnswer: "submittedAnswer",
            _teacherInputRequired: "teacherInputRequired",
            _teacherInputProvided: "teacherInputProvided",
        }),

        classNames() {
            let color = "primary";

            if (this._graded) {
                if (this._teacherInputProvided(this.question.clientId)) {
                    color = "text-primary-darker";
                } else if (this._teacherInputRequired(this.question.clientId)) {
                    color = "text-warning";
                } else if (this._isQuestionIncorrect(this.question.clientId)) {
                    color = "text-incorrect";
                } else if (this._isQuestionPartiallyCorrect(this.question.clientId)) {
                    color = "";
                } else {
                    color = "text-correct";
                }
            } else {
                color = this.isAnswered ? "text-primary-darker" : "text-warning";
            }

            return [color];
        },

        needsStack() {
            return this._graded && this._isQuestionPartiallyCorrect(this.question.clientId);
        },

        iconName() {
            if (this._graded) {
                if (this._teacherInputProvided(this.question.clientId)) {
                    return "thumbs-up";
                }
                if (this._teacherInputRequired(this.question.clientId)) {
                    return "exclamation-circle";
                }
                if (this._isQuestionCorrect(this.question.clientId)) {
                    return "check-circle";
                }

                return "times-circle";
            }

            return this.isAnswered ? "check-circle" : "exclamation-circle";
        },

        isAnswered() {
            return this.submittedAnswer !== undefined;
        },

        question_number() {
            return _.trimEnd(this.question.number, ".");
        },

        submittedAnswer() {
            return this._submittedAnswer(this.question.clientId);
        },
    },

    props: ["question", "page"],

    methods: {
        ...mapActions({
            _navigateToPage: "navigateToPage",
        }),

        navigateToPage(order) {
            if (this._navigating || (this._isSample && !this.page.is_sample)) {
                return;
            }

            this._navigateToPage(order).then(() => {
                if (!this.navigateToQuestion()) {
                    // If in review page, navigate to page/question require a delay for the page to be rendered. Wait and try again.
                    setTimeout(() => {
                        this.navigateToQuestion();
                    }, 1000);
                }
            });
        },
        navigateToQuestion() {
            const element = document.getElementById(`question-${this.question.clientId}`);
            if (element) {
                element.scrollIntoView();
                return true;
            }
            return false;
        },
    },
};
</script>

<template>
    <button
        class="my-4"
        :class="_isSample && !page.is_sample ? 'cursor-not-allowed' : ''"
        @click="navigateToPage(page.order)"
    >
        <FontAwesomeLayers
            class="fa-lg"
            v-if="_teacherInputProvided(question.clientId)"
        >
            <FontAwesomeIcon icon="circle" />

            <FontAwesomeIcon
                icon="thumbs-up"
                transform="shrink-7 up-1"
                :style="{ color: 'white' }"
            />
        </FontAwesomeLayers>

        <FontAwesomeLayers
            class="fa-lg"
            v-else-if="needsStack"
        >
            <FontAwesomeIcon
                icon="circle"
                class="circle-left"
            />

            <FontAwesomeIcon
                icon="circle"
                class="circle-right"
                style=""
            />

            <FontAwesomeIcon
                icon="times"
                transform="shrink-6"
                :style="{ color: 'white' }"
            />
        </FontAwesomeLayers>

        <FontAwesomeIcon
            :icon="iconName"
            size="lg"
            :class="classNames"
            v-else
        />

        Question {{ question_number }}
    </button>
</template>

<style lang="scss">
.circle-left {
    @apply text-incorrect;
    clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
}

.circle-right {
    @apply text-correct;
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
}
</style>
