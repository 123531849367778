<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        level: {
            type: Number,
            default: 0,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
        samplePages: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {};
    },
    methods: {
        toggleChildList(pageNumber) {
            const ele = document.getElementById(`${this.level}-${pageNumber}`);
            ele.classList.toggle("hidden");

            const eleIcon = document.getElementById(`${this.level}-${pageNumber}-icon`);
            if (window.getComputedStyle(ele).display === "none") {
                eleIcon.classList.remove("rotate-90");
                eleIcon.classList.remove("transform-gpu");
            } else {
                eleIcon.classList.add("rotate-90");
                eleIcon.classList.add("transform-gpu");
            }
        },
        pageClicked(pageNumber) {
            if (!this.linkEnabled(pageNumber)) {
                return;
            }
            this.$emit("goToPage", pageNumber);
        },
        linkEnabled(pageNumber) {
            return !this.isPreview || this.samplePages.includes(parseInt(pageNumber));
        },
    },
};
</script>

<template>
    <ul
        v-if="items.length > 0"
        class="list-none text-sm"
    >
        <li v-for="item in items">
            <div
                class="flex"
                :class="linkEnabled(item.pageNumber) ? 'cursor-pointer hover:bg-slate-400' : 'text-gray-600'"
            >
                <button
                    v-if="item.items && item.items.length > 0"
                    @click="toggleChildList(item.pageNumber)"
                    class="flex-initial pl-2"
                >
                    <i
                        :id="level + '-' + item.pageNumber + '-icon'"
                        class="fas fa-angle-right"
                    />
                </button>

                <button
                    :disabled="!linkEnabled(item.pageNumber)"
                    @click="pageClicked(item.pageNumber)"
                    class="block flex-auto px-2 py-1 text-left"
                >
                    {{ item.title }}
                </button>
            </div>

            <PdfToc
                v-if="item.items && item.items.length > 0"
                :id="level + '-' + item.pageNumber"
                :items="item.items"
                :level="level + 1"
                :is-preview="isPreview"
                :sample-pages="samplePages"
                @go-to-page="pageClicked"
                class="hidden pl-4"
            />
        </li>
    </ul>
</template>

<style scoped></style>
