<script setup lang="ts">
const { route } = window;

const link =
    "https://clever.com/oauth/authorize?response_type=code" +
    `&redirect_uri=${route("oauth.classlink.redirect")}` +
    `&client_id=${import.meta.env.VITE_CLASSLINK_CLIENT_ID}`;
</script>

<template>
    <a
        :href="link"
        class="group block min-h-[52px]"
    >
        <div
            class="group inline-flex h-12 w-full items-center justify-center rounded border border-primary-lighter bg-white py-1 pr-2 text-primary-lighter hover:bg-primary-lighter hover:text-white"
        >
            <div class="inline-flex w-64 items-center justify-start">
                <div class="mr-4 p-2 group-hover:rounded-sm group-hover:bg-white">
                    <img
                        src="/images/class-link-logo.png"
                        alt="ClassLink logo"
                        class="w-6"
                    />
                </div>

                <span>Sign in with ClassLink</span>
            </div>
        </div>
    </a>
</template>
