<script lang="ts" setup></script>
<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            fill="currentColor"
            d="M4 15V9h8V4.16L19.84 12L12 19.84V15z"
        ></path>
    </svg>
</template>
