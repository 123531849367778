<script setup lang="ts">
import { Head, useForm, usePage } from "@inertiajs/vue3";
import AppLayout from "@/layouts/AppLayout.vue";
import LabelTag from "@/components/Form/LabelTag.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ArrowRightBold from "@/components/Icons/ArrowRightBold.vue";
import ListErrors from "@/components/Global/ListErrors.vue";
import CText from "@/components/Form/CText.vue";
import CCheckbox from "@/components/Form/CCheckbox.vue";

const form = useForm({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    agree: false,
    processing: false,
    errors: {},
});
const submit = async () => {
    form.clearErrors();
    // Check if password and password_confirmation are the same
    if (form.password !== form.password_confirmation) {
        form.errors.password_confirmation = "The password confirmation does not match.";
    }
    if (!form.agree) {
        form.errors.agree = "You must agree to the terms and conditions.";
    }

    if (Object.keys(form.errors).length > 0) {
        return;
    }

    form.processing = true;
    form.post(route("register"), {
        onFinish: () => {},
        onSuccess: () => {
            form.processing = false;
        },
        onError: (errors) => {
            console.log(errors);
        },
    });
};

const checkboxAgreeLabel =
    'I agree with the <a href="/terms-of-service" target="_blank" class="text-primary-lighter hover:underline">Terms & Conditions</a>' +
    ' and <a href="/privacy-policy" target="_blank" class="text-primary-lighter hover:underline">Privacy Policy</a>.';
</script>

<template>
    <AppLayout>
        <Head title="Register Account" />

        <main
            class="relative bg-cover bg-center bg-no-repeat"
            style="background-image: url(/images/BgLogin.svg)"
            :style="'min-height:' + (window.innerHeight - 105) + 'px'"
        >
            <div class="mx-4 min-h-full py-10 md:mx-auto md:w-1/2 xl:w-1/3">
                <div class="mb-2 w-full text-center md:mb-10">
                    <img
                        class="mx-auto hidden w-72 sm:block"
                        src="/images/CourseWaveLogo.svg"
                        :alt="String(usePage().props.appName)"
                    />
                </div>

                <div class="overflow-hidden rounded border bg-white px-10 py-14 shadow-card">
                    <h1 class="mb-4 text-center text-3xl">Register Account</h1>

                    <ListErrors
                        :errors="form.errors"
                        class="mb-2"
                    />

                    <form @submit.prevent="submit">
                        <div class="mb-4">
                            <LabelTag for="first_name">First Name</LabelTag>

                            <CText
                                id="first_name"
                                v-model="form.first_name"
                                placeholder="Enter your first name"
                                required
                            ></CText>
                        </div>

                        <div class="mb-4">
                            <LabelTag for="last_name">Last Name</LabelTag>

                            <CText
                                id="last_name"
                                v-model="form.last_name"
                                placeholder="Enter your last name"
                                required
                            ></CText>
                        </div>

                        <div class="mb-4">
                            <LabelTag for="email">E-mail</LabelTag>

                            <CText
                                id="email"
                                v-model="form.email"
                                type="email"
                                placeholder="Enter your email"
                                required
                            ></CText>
                        </div>

                        <div class="mb-4">
                            <LabelTag for="password">Password</LabelTag>

                            <CText
                                id="password"
                                v-model="form.password"
                                type="password"
                                placeholder="Enter your password"
                                required
                            ></CText>
                        </div>

                        <div class="mb-4">
                            <LabelTag for="password_confirmation">Confirm Password</LabelTag>

                            <CText
                                id="password_confirmation"
                                v-model="form.password_confirmation"
                                type="password"
                                placeholder="Confirm your password"
                                required
                            ></CText>
                        </div>

                        <div>
                            <CCheckbox
                                v-model="form.agree"
                                input-value="true"
                                :label="checkboxAgreeLabel"
                                required
                            />
                        </div>

                        <div class="mt-6 text-center">
                            <BtnPrimary
                                class="w-full lg:w-4/5"
                                :disabled="form.processing"
                            >
                                Register
                                <ArrowRightBold class="inline-block w-6" />
                            </BtnPrimary>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    </AppLayout>
</template>
