<script lang="ts" setup>
import { ref } from "vue";
import { usePage } from "@inertiajs/vue3";

const page = usePage();
const impersonating = ref(page.props.auth?.user?.impersonating ?? false);
</script>

<template>
    <div
        v-if="impersonating"
        class="absolute bottom-2 right-0 rounded-bl-lg rounded-tl-lg bg-primary p-2 text-white"
    >
        <a :href="route('impersonate.stop')">Stop Impersonating</a>
    </div>
</template>

<style scoped></style>
