const pdf = class Pdf {
    constructor(file = null, pageData = null, createTOC = false) {
        this.file = file;
        this.pageData = pageData;
        this.createTOC = createTOC;
        this.documentProxy = null;
        this.tableOfContents = null;

        if (this.pageData) {
            this.createTOC = false;
        }
    }

    // Getters

    // Methods
    init() {
        return new Promise((resolve, reject) => {
            // Loader error. Use legacy instead.
            import("pdfjs-dist/build/pdf").then(async (pdfjsLib) => {
                const pdfjsLibHolder = pdfjsLib;
                // Make sure the file version matches the pdfjs version in package.json
                const workerSrc = await import("pdfjs-dist/build/pdf.worker");

                pdfjsLibHolder.GlobalWorkerOptions.workerSrc = workerSrc;

                // let pdfData = atob();
                let params = {};
                if (this.file) {
                    params = { url: this.file, disableAutoFetch: true, disableStream: true };
                } else if (this.pageData) {
                    params = { data: atob(this.pageData) };
                }

                pdfjsLib.getDocument(params).promise.then((pdfResult) => {
                    const thisPdf = pdfResult;
                    this.documentProxy = thisPdf;
                    if (this.createTOC) {
                        this.documentProxy.getOutline().then((outline) => {
                            this.tableOfContents = this.getTableOfContents(outline);
                        });
                    }

                    thisPdf.pdfjsLib = pdfjsLib;
                    resolve(pdfResult);
                });
            });
        });
    }

    getTableOfContents(outline, pairs = []) {
        // Loop through the tree outline
        if (outline) {
            for (let i = 0; i < outline.length; i += 1) {
                // Get page number from destination
                if (typeof outline[i].dest === "string") {
                    this.documentProxy
                        .getDestination(outline[i].dest)
                        .then((destination) => {
                            this.documentProxy.getPageIndex(destination[0]).then((index) => {
                                const items = this.getTableOfContents(outline[i].items);
                                pairs.push({
                                    title: outline[i].title.replace(/(\r\n|\n|\r)/gm, " ").trim(),
                                    pageNumber: parseInt(index, 10) + 1,
                                    items,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    alert("Invalid table of content. Please make sure the page destination is type string.");
                    break;
                }
            }
        }
        return pairs;
    }
};

export default pdf;
