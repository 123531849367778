<script setup lang="ts">
const { route } = window;
</script>

<template>
    <a
        :href="route('auth.redirect', ['google'])"
        class="group mb-0.5 block"
    >
        <div
            class="group inline-flex h-12 w-full items-center justify-center rounded border border-primary-lighter bg-white py-1 pr-2 text-primary-lighter hover:bg-primary-lighter hover:text-white"
        >
            <div class="inline-flex w-64 items-center justify-start">
                <div class="mr-4 p-2 group-hover:rounded-sm group-hover:bg-white">
                    <img
                        src="/images/GoogleLogo.png"
                        alt="Google logo"
                        class="w-6"
                    />
                </div>

                <span>Sign in with Clever</span>
            </div>
        </div>
    </a>
</template>
