<script lang="ts" setup>
import HeaderBlock from "@/components/Global/HeaderBlock.vue";
import { usePage } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";
import ImpersonateStopBtn from "@/components/Global/ImpersonateStopBtn.vue";
import HeaderQuestionListToggleBtn from "@/components/Global/HeaderQuestionListToggleBtn.vue";
import Pagination from "@/components/TestTaking/Pagination.vue";

defineProps({
    showActionBtns: {
        type: Boolean,
        default: true,
    },
    showBtnQuestionList: {
        type: Boolean,
        default: false,
    },
    showExitPreviewBtn: {
        type: Boolean,
        default: false,
    },
    exitPreviewLink: {
        type: String,
        default: "javascript:close()",
    },
    exitPreviewLabel: {
        type: String,
        default: "Exit Preview",
    },
    subtitle: {
        type: String,
        default: "",
    },
});

const page = usePage();

const user = (page.props.auth.user as User) ?? false;
Sentry.setContext("User Type", user.role);

if (user && ["teacher", "test-creator"].includes(user.role)) {
    Sentry.setUser({
        email: user.email,
        name: user.name,
    });
}
</script>

<template>
    <div
        id="app"
        class="flex h-screen flex-col"
    >
        <HeaderBlock :show-action-btns="showActionBtns">
            <template #before-header>
                <header
                    v-if="showExitPreviewBtn && user?.role.toLowerCase() !== 'student'"
                    class="sticky top-0 flex h-10 w-full bg-primary-darker px-4 py-6 text-white"
                >
                    <div class="flex w-1/2 flex-1 items-center justify-end md:w-1/4">
                        <a
                            :href="exitPreviewLink"
                            class="mr-2 items-center rounded bg-transparent px-4 py-2 text-center font-bold text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none"
                        >
                            <span class="mr-2 inline-block">{{ exitPreviewLabel }}</span>

                            <i class="fas fa-times fa-lg"></i>
                        </a>
                    </div>
                </header>
            </template>

            <template #btn-question-list>
                <HeaderQuestionListToggleBtn
                    v-if="showBtnQuestionList"
                    class="w-auto flex-none"
                />
            </template>

            <template #center-header>
                <Pagination v-if="showBtnQuestionList" />
            </template>

            <template #subtitle>
                <div
                    v-if="subtitle"
                    class="bg-gray-200 p-2 text-center"
                >
                    <a
                        :href="route('home')"
                        title="Resource Home"
                        class="inline-flex"
                        v-html="subtitle"
                    >
                    </a>
                </div>
            </template>
        </HeaderBlock>

        <!-- Main body slot: Start --->
        <main class="flex-grow overflow-y-auto">
            <slot />
        </main>
        <!-- Main body slot: End --->

        <ImpersonateStopBtn />
    </div>
</template>
