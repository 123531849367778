<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import "~/mathlive/dist/mathlive-static.css";
import SavingProgress from "@/components/TestTaking/Blocks/SavingProgress.vue";
import Sidebar from "@/components/TestTaking/sidebar/SidebarContent.vue";
import { useBroadcastChannel } from "@vueuse/core";

export default {
    setup() {
        const { isSupported, data } = useBroadcastChannel({ name: "test_submitted_channel" });

        return {
            isBCSupported: isSupported,
            channelData: data,
        };
    },
    components: {
        SavingProgress,
        Sidebar,
    },

    computed: {
        ...mapGetters({
            _isSidebarOpen: "isSidebarOpen",
            _testSubmitted: "testSubmitted",
        }),
    },

    props: {
        user: {
            default() {
                return {};
            },
        },
        test: {
            default() {
                return {};
            },
        },
        initialPage: {
            default() {
                return {};
            },
        },

        graded: {
            default: false,
            type: Boolean,
        },

        initialPages: {
            default() {
                return [];
            },
        },

        lastQuestion: {
            default() {
                return {};
            },
        },

        preview: {
            default: false,
            type: Boolean,
        },

        reviewing: {
            default: false,
            type: Boolean,
        },

        sample: {
            default: false,
            type: Boolean,
        },

        secret: {
            default: "",
            type: String,
        },

        submission: {
            default() {
                return {};
            },
        },
    },

    methods: {
        ...mapActions({
            _createSubmission: "createSubmission",
            _getCorrectAnswers: "getCorrectAnswers",
            _updateLastCompletedPage: "updateLastCompletedPage",
        }),

        ...mapMutations([
            "updatePage",
            "updatePages",
            "updateSubmission",
            "setGraded",
            "setPreview",
            "setSample",
            "setSecret",
            "setResource",
            "setUser",
        ]),
    },

    async created() {
        this.setUser(this.user);
        this.setPreview(this.preview);
        this.setSample(this.sample);
        this.setGraded(this.graded);
        this.updatePage(this.initialPage);
        this.updatePages(this.initialPages);
        this.setResource(this.test.resource);

        this.updateSubmission(this.submission);

        const lastPageNumber = _.get(this.lastQuestion, "page.order", null);
        if (lastPageNumber && lastPageNumber > 2) {
            this._updateLastCompletedPage(lastPageNumber - 1);
        }

        if (this.secret !== "") {
            this.setSecret(this.secret);
            this._getCorrectAnswers();
        }
    },
    watch: {
        channelData(value) {
            // When broadcast channel receives a message, reload the page
            window.location.reload();
        },
    },
};
</script>

<template>
    <div class="relative flex h-full">
        <Sidebar
            class="h-full w-48"
            v-show="_isSidebarOpen"
            :test="test"
        />

        <SavingProgress></SavingProgress>

        <div
            class="relative h-full w-full overflow-y-auto"
            id="test-page-container"
        >
            <div class="px-5 pt-12">
                <RouterView
                    class=""
                    :key="$route.fullPath"
                />
            </div>
        </div>
    </div>
</template>
