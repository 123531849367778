<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
import { usePage } from "@inertiajs/vue3";
import LoginUsingCode from "@/components/Login/LoginUsingCode.vue";
import LoginForm from "@/components/Login/LoginForm.vue";
import { ref } from "vue";
import BtnGoogle from "@/components/Login/BtnGoogle.vue";
import BtnClever from "@/components/Login/BtnClever.vue";
import BtnMicrosoft from "@/components/Login/BtnMicrosoft.vue";
import BtnClassLink from "@/components/Login/BtnClassLink.vue";

const invalidOauthMsg = ref("");

const props = defineProps({
    code: {
        type: String,
        required: false,
    },
});
</script>

<template>
    <AppLayout>
        <main
            class="relative h-full bg-cover bg-center bg-no-repeat"
            style="background-image: url(/images/BgLogin.svg)"
        >
            <div class="flex flex-wrap place-content-center py-10">
                <div class="mb-2 w-full text-center md:mb-10">
                    <img
                        class="mx-auto hidden w-72 md:block"
                        src="/images/CourseWaveLogo.svg"
                        :alt="String(usePage().props.appName)"
                    />
                </div>

                <div
                    class="relative mx-0 mb-10 w-full max-w-4xl overflow-hidden rounded border bg-white p-6 shadow-card md:mx-4 md:flex md:items-center md:justify-center"
                >
                    <div class="items-center md:w-1/2 md:flex-1 lg:w-1/3 xl:w-1/4 2xl:w-1/5">
                        <LoginUsingCode :code="code" />
                    </div>

                    <div class="relative mb-10 items-center border border-gray-500 md:mb-0 md:flex md:h-full md:w-0">
                        <span
                            class="absolute -bottom-3.5 right-1/2 h-8 bg-white px-2 leading-8 md:-right-2 md:bottom-auto md:w-4 md:px-0"
                            >or</span
                        >
                    </div>

                    <div class="items-center md:flex-1">
                        <LoginForm />

                        <div class="mb-4 w-full px-8 pb-8">
                            <div
                                v-if="invalidOauthMsg"
                                class="relative mb-2 rounded border border-red-400 bg-red-100 px-2 text-center text-sm text-red-700"
                                role="alert"
                            >
                                {{ invalidOauthMsg }}
                            </div>

                            <BtnGoogle />

                            <BtnMicrosoft />

                            <BtnClever />

                            <BtnClassLink />

                            <div class="mt-2 text-sm">
                                <a
                                    href="/terms-of-service"
                                    target="_blank"
                                    class="text-sm text-primary-lighter hover:underline"
                                    >Terms & Conditions</a
                                >
                                and
                                <a
                                    href="/privacy-policy"
                                    target="_blank"
                                    class="text-sm text-primary-lighter hover:underline"
                                    >Privacy Notice</a
                                >
                            </div>
                        </div>

                        <div class="absolute bottom-2 right-4">
                            <a
                                href="https://help.coursewave.com"
                                target="_blank"
                                class="text-primary-lighter hover:underline"
                            >
                                <i class="fas fa-info-circle mr-2 text-blue-500"></i>

                                <span class="text-sm">Help</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </AppLayout>
</template>
