<script>
import Draggable from "vuedraggable";
import "~/mathlive/dist/mathlive-static.css";
import BarsSolid from "@/components/Icons/BarsSolid.vue";
import Swal from "sweetalert2";
import axios from "axios";
import { defineAsyncComponent } from "vue";
import { useBroadcastChannel } from "@vueuse/core"; // Bring TestBuilder in as an async component to make sure it separates out its code from the main bundle.

// Bring TestBuilder in as an async component to make sure it separates out its code from the main bundle.
// The test builder is very large, so this helps the bundler organize things
const TestBuilder = defineAsyncComponent(() => import("@/components/TestCreation/TestBuilder.vue"));

export default {
    setup() {
        const { isSupported, post } = useBroadcastChannel({ name: "preview_channel" });

        const broadcastMessage = (message) => {
            if (!isSupported.value) {
                return;
            }

            post(message);
        };

        return {
            broadcastMessage,
        };
    },

    data() {
        return {
            activePage: { id: null },
            pages: [],
            sidebarOpen: true,
            isSaving: false,
        };
    },

    computed: {
        sidebarClasses() {
            return {
                "w-1/8": this.sidebarOpen,
                "w-auto": !this.sidebarOpen,
                "border-r": !this.activePage.id,
            };
        },
    },

    components: {
        BarsSolid,
        Draggable,
        TestBuilder,
    },

    props: ["initialPages", "test"],

    methods: {
        adjustPages(min, max = null) {
            _.each(this.pages, (page, index) => {
                if (index >= min && (max === null || (max !== null && index <= max))) {
                    page.newOrder = index + 1;
                }
            });

            setTimeout(this.finishOrderShift, 1500);
        },

        updateRoute() {
            const params = {
                page: this.activePage.order,
            };

            // Update route URL. It appears we can't just do a push to update the query params.
            // It will complain about no match route. So we have to add a new route and then replace it.
            this.$router.addRoute({
                name: "test-creator.tests.edit",
                path: this.$route.path,
            });
            this.$router.replace({
                name: "test-creator.tests.edit",
                query: params,
            });
        },
        changePage(page, skipCheck = false) {
            if (skipCheck) {
                this.activePage = page;

                return;
            }

            let prompt;

            if (this.isContentDirty()) {
                prompt = Swal.fire({
                    title: "Are you sure you want to leave?",
                    text: "Content has been changed and will be lost by changing pages",
                    icon: "warning",
                    showCancelButton: true,
                    // showDenyButton: true,
                    confirmButtonText: "Save and Continue",
                    cancelButtonText: "Don't save changes",
                    // denyButtonText: 'Don\'t save changes'
                });
            } else {
                prompt = new Promise((resolve) => {
                    resolve({ value: false });
                });
            }

            prompt.then(({ value }) => {
                if (value) {
                    this.savePageContent()
                        .then(() => {
                            this.activePage = page;
                        })
                        .catch(() => {
                            this.activePage = this.activePage;
                        });
                } else {
                    this.activePage = page;
                    this.updateRoute();
                }
            });
        },

        changeSample(value) {
            const index = _.findIndex(this.pages, { id: this.activePage.id });
            this.pages[index].is_sample = value;
            this.$emit("update-has-samples", value);
        },

        createPage() {
            const newPage = {
                id: _.now(),
                order: (_.maxBy(this.pages, "order") || { order: 0 }).order + 1,
                content: [],
            };

            this.pages.push(newPage);

            this.savePage(newPage, true);

            this.$nextTick(() => {
                this.activePage = newPage;
            });
        },

        deletePage() {
            const page = this.activePage;

            const confirmed = confirm(`Delete Page ${page.order}`);

            if (confirmed) {
                const url = `/api/resources/${this.test.resource_id}/tests/${this.test.id}/pages/${page.id}`;
                axios
                    .delete(url)
                    .then(({ data }) => {
                        if (data.success) {
                            let index = _.findIndex(this.pages, { id: page.id });

                            this.pages.splice(index, 1);

                            this.adjustPages(index);

                            if (this.pages.length === 0) {
                                return this.changePage({ id: null }, true);
                            }
                            if (this.pages.length === index) {
                                index--;
                            }

                            this.changePage(this.pages[index], true);
                        }
                    })
                    .catch((errors) => {
                        console.error(errors);
                    });
            }
        },

        getBlocks() {
            return window.wp.data.select("core/block-editor").getBlocks();
        },

        getBlockHtml(block) {
            if (block.name.indexOf("core-embed") > -1) {
                return window.wp.blocks.serialize(block, {});
            }

            return window.wp.blocks.getBlockContent(block);
        },

        finishOrderShift() {
            _.each(this.pages, (page) => {
                if (page.newOrder) {
                    page.order = page.newOrder;
                    delete page.newOrder;
                }
            });
        },

        isContentDirty() {
            const blocks = _.reduce(this.getBlocks(), this.reduceBlocks, {});
            const isDirty = { value: false };

            const page = _.find(this.pages, { id: this.activePage.id });
            let { content } = page;

            if (typeof content === "string") {
                content = JSON.parse(content);
            }

            if (Object.keys(blocks).length > 0 && content.length > 0) {
                _.forEachRight(content, this.blockChecker(blocks, isDirty));

                // There are new blocks
                if (_.keys(blocks).length > 0) {
                    isDirty.value = true;
                }
            } else {
                isDirty.value = Object.keys(blocks).length !== content.length;
            }

            return isDirty.value;
        },

        blockChecker(blocks, isDirty) {
            return (block, index) => {
                const matchedBlock = blocks[block.clientId];

                if (matchedBlock === null || matchedBlock === undefined) {
                    // console.log('Cannot find block');
                    isDirty.value = true;

                    return false;
                }
                if (matchedBlock.index !== index) {
                    // console.log('Block has moved positions');
                    isDirty.value = true;

                    return false;
                }
                if (
                    ((Array.isArray(block.attributes) && block.attributes.length === 0) ||
                        (typeof block.attributes === "object" &&
                            block.attributes !== null &&
                            Object.keys(block.attributes).length === 0)) &&
                    ((Array.isArray(matchedBlock.attributes) && matchedBlock.attributes.length === 0) ||
                        (typeof matchedBlock.attributes === "object" &&
                            matchedBlock.attributes !== null &&
                            Object.keys(matchedBlock.attributes).length === 0))
                ) {
                    // Check to prevent the next check from creating a false positive
                } else if (!_.isEqual(block.attributes, matchedBlock.attributes)) {
                    // console.log('attributes don\'t match', block, matchedBlock);
                    isDirty.value = true;

                    return false;
                }

                if (block.innerBlocks && block.innerBlocks.length) {
                    _.forEachRight(block.innerBlocks, this.blockChecker(blocks, isDirty));
                }

                delete blocks[block.clientId];
            };
        },

        rearrangePages({ moved }) {
            const { newIndex, oldIndex, element } = moved;

            if (newIndex - oldIndex > 0) {
                this.adjustPages(oldIndex, newIndex);
            } else if (newIndex - oldIndex < 0) {
                this.adjustPages(newIndex, oldIndex);
            }

            const url = `/api/resources/${this.test.resource_id}/tests/${this.test.id}/pages/${element.id}/reorder`;

            axios.post(url, {
                new_order: newIndex + 1,
                old_order: oldIndex + 1,
            });
        },

        reduceBlocks(carry, block, index) {
            const { callback, ...attributes } = block.attributes;

            carry[block.clientId] = {
                attributes,
                index,
            };

            if (block.innerBlocks.length) {
                _.reduce(block.innerBlocks, this.reduceBlocks, carry);
            }

            return carry;
        },

        savePageContent() {
            const index = _.findIndex(this.pages, { id: this.activePage.id });

            const blocks = this.getBlocks();

            blocks.forEach((block) => {
                block.html = this.getBlockHtml(block);
            });

            this.pages[index].content = blocks;

            return this.savePage(this.pages[index]);
        },

        savePage(page, create = false) {
            let url = `/api/resources/${this.test.resource_id}/tests/${this.test.id}/pages`;
            let method = "post";

            if (page.created_at !== undefined) {
                url += `/${page.id}`;
                method = "patch";
            }

            return axios
                .request({
                    method,
                    url,
                    data: page,
                })
                .then(({ data }) => {
                    const index = _.findIndex(this.pages, { id: page.id });

                    this.pages[index] = { ...data };

                    if (method === "post") {
                        this.activePage = { ...data };
                    }

                    Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: `The page has been successfully ${create ? "created" : "saved"}.`,
                    }).then(() => {
                        this.$nextTick(() => {
                            const element = document.getElementById(`page-${this.activePage.id}`);
                            element.scrollIntoView();

                            this.broadcastMessage("New content saved!");
                        });
                    });
                })
                .catch(({ response }) => {
                    if (response?.status === 422) {
                        Swal.fire({
                            icon: "error",
                            title: "Invalid Entry",
                            text: response.data.errors,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Whoops...",
                            text: "Something unexpected happened. Please try again later.",
                        });
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
    },

    created() {
        let page = _.first(this.initialPages);

        if (this.$route.query.page) {
            page = _.find(this.initialPages, { order: parseInt(this.$route.query.page) });
        }

        if (page) {
            this.activePage = page;
        }

        this.pages = [...this.initialPages];
    },
};
</script>

<template>
    <div class="flex w-full">
        <div
            class="sidebar flex flex-shrink flex-col rounded-bl rounded-tl border border-r-0"
            :class="sidebarClasses"
        >
            <div
                class="flex border-b px-3"
                :class="{ 'justify-center pb-4 pt-5': !sidebarOpen, 'content-between py-3': sidebarOpen }"
            >
                <span
                    class="flex-1 text-2xl"
                    v-if="sidebarOpen"
                    >Pages</span
                >

                <button
                    @click="sidebarOpen = !sidebarOpen"
                    aria-label="Collapse Sidebar"
                    data-balloon-pos="up"
                >
                    <FontAwesomeIcon
                        :icon="['fa', `caret-square-${sidebarOpen ? 'left' : 'right'}`]"
                        size="lg"
                    />
                </button>
            </div>

            <div class="sidebar-pages flex-1">
                <Draggable
                    class="pages flex flex-col"
                    :class="{ 'items-center': !sidebarOpen }"
                    v-model="pages"
                    @change="rearrangePages"
                    handle=".handle"
                    item-key="id"
                >
                    <template #item="{ element: page }">
                        <button
                            class="page group flex items-center border-t px-3 py-2 first:border-t-0 last:border-b"
                            :class="{
                                'bg-primary-lighter text-white': activePage.id === page.id,
                                'hover:cursor-pointer hover:bg-primary-lighter hover:text-white':
                                    activePage.id !== page.id,
                            }"
                            :id="`page-${page.id}`"
                            @click="changePage(page)"
                        >
                            <BarsSolid
                                class="handle mr-3 h-4 w-4 group-hover:fill-white"
                                :class="activePage.id === page.id ? 'fill-white' : 'fill-black'"
                                v-if="sidebarOpen"
                            />

                            <span
                                class="flex flex-1 items-start"
                                v-if="sidebarOpen"
                                >Page {{ page.order }}</span
                            >

                            <span
                                class="min-w-7/8r"
                                v-else
                                >{{ page.order }}</span
                            >

                            <span
                                class="text-sm opacity-75"
                                v-if="page.newOrder"
                            >
                                <span class="mx-2"> => </span>

                                Page {{ page.newOrder }}
                            </span>

                            <span
                                class="rounded-full bg-orange-500 px-2 py-0.5 text-xxs font-bold uppercase text-white"
                                v-else-if="sidebarOpen && page.is_sample"
                            >
                                Sample
                            </span>
                        </button>
                    </template>
                </Draggable>
            </div>

            <div class="footer">
                <button
                    id="btnAddPage"
                    class="list-group-item border-t px-3 py-2 text-left"
                    @click="createPage"
                >
                    <FontAwesomeIcon icon="plus" />

                    <span
                        class="ml-3"
                        v-if="sidebarOpen"
                        >Add Page</span
                    >
                </button>
            </div>
        </div>

        <div
            class="flex-grow"
            v-show="activePage.id"
        >
            <TestBuilder
                :content="activePage.content"
                :resource-id="test.resource_id"
                v-model:saving="isSaving"
                :test-id="activePage.test_id"
                :page="activePage.order"
                :page-is-sample="activePage.is_sample == true"
                @save-page="savePageContent"
                @delete-page="deletePage"
                @change-sample="changeSample"
            ></TestBuilder>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.page {
    @apply relative;

    button {
        opacity: 0;
    }

    &:hover button {
        opacity: 1;
    }
}

.handle {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

span.newOrder {
    font-size: 0.8em;
    opacity: 0.7;
}

.sidebar {
    .sidebar-pages {
        min-height: 457px;
        overflow-y: auto;
        max-height: calc(100vh - (20rem + 36px));

        .pages {
        }
    }

    .footer button {
        width: 100%;
        height: 57px;
    }
}
</style>
