<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
import { Head } from "@inertiajs/vue3";
import { PropType } from "vue";
import Resource from "@/types/Resource";
import TestType from "@/types/Test";
import Page from "@/types/Page";
import Submission from "@/types/Submission";
import Test from "@/components/TestTaking/Test.vue";

const props = defineProps({
    resource: {
        type: Object as PropType<Resource>,
        required: true,
    },
    test: {
        type: Object as PropType<TestType>,
        required: true,
    },
    page: {
        type: Object as PropType<Page>,
        required: true,
    },
    pages: {
        type: Array as PropType<Page[]>,
        required: true,
    },
    submission: {
        type: Object as PropType<Submission>,
        default: null,
    },
    secretKey: {
        type: String,
        default: "",
    },
});

const headerTitle = `Submissions - ${props.test?.title ?? ""}`;
const subtitle =
    `<h1 class="sm:inline text-sm md:text-base pl-6">${props.resource.title}:&nbsp</h1>` +
    `<h2 class="sm:inline text-sm md:text-base font-light">${props.test.title}</h2>`;
</script>

<template>
    <AppLayout
        :show-action-btns="true"
        :show-btn-question-list="true"
        :show-exit-preview-btn="true"
        :exit-preview-link="route('teacher.resource.show-tab', { resource: resource.id, tab: 'submissions' })"
        exit-preview-label="Back to Submissions"
        :subtitle="subtitle"
    >
        <Head :title="headerTitle" />

        <Test
            :test="test"
            :initial-page="page"
            :initial-pages="pages"
            :submission="submission"
            :secret="secretKey"
            graded
            reviewing
        />
    </AppLayout>
</template>
