<script setup lang="ts">
import logoWhite from "@images/CourseWaveLogoWhite.svg";
import logoNoText from "@images/ABC_Logo_No_Text.svg";
import HeaderUser from "@/components/Global/HeaderUser.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";

const props = defineProps({
    showActionBtns: {
        type: Boolean,
        default: true,
    },
});

const userLoggedIn = computed(() => usePage().props?.auth?.user || usePage().props?.auth?.resource);
const routeHome = userLoggedIn.value ? route("home") : "/";

const showLoginBtn = computed(
    () =>
        props.showActionBtns &&
        !userLoggedIn.value &&
        !window.location.pathname.includes("login") &&
        !window.location.pathname.includes("account-setup") &&
        !window.location.pathname.includes("password"),
);
</script>

<template>
    <div>
        <slot name="before-header" />

        <header class="relative top-0 flex h-26 w-full items-center bg-primary p-4 text-white print:hidden">
            <div class="flex h-full w-full items-center justify-between">
                <slot name="btn-question-list" />

                <div class="flex flex-1 items-center">
                    <a
                        :href="routeHome"
                        title="Home"
                        class="flex-none"
                    >
                        <img
                            class="w-32 md:w-40"
                            :src="logoWhite"
                            alt="@yield('title', config('app.name'))"
                        />
                    </a>

                    <a
                        :href="routeHome"
                        title="Home"
                        class="ml-2 mr-1 mt-1 flex-none text-sm font-light tracking-widest md:mt-2 md:text-lg"
                        style="padding-top: 2px"
                    >
                        <span class="text-lg md:text-xl">B</span>y
                    </a>

                    <a
                        :href="routeHome"
                        title="Home"
                        class="inline-flex"
                    >
                        <img
                            class="w-8 md:w-10"
                            :src="logoNoText"
                            alt="@yield('title', config('app.name'))"
                        />
                    </a>
                </div>

                <div class="absolute w-fit md:right-4 lg:left-[42%] lg:w-[300px]">
                    <slot name="center-header" />
                </div>

                <!--<div class="flex inline-flex items-center justify-end xl:w-1/4">-->
                <!--    <a :href="routeHome" class="float-right text-sm"> Dashboard </a>-->
                <!--</div>-->

                <HeaderUser v-if="showActionBtns && $page.props.auth.user" />

                <!-- Don't show login button on login page -->
                <BtnPrimary
                    v-else-if="showLoginBtn"
                    type="link"
                    :href="route('login')"
                    class="mr-2 h-50p py-4"
                    label-position="down"
                >
                    <i class="fas fa-sign-in-alt fa-lg"></i>

                    <span class="ml-1">Login</span>
                </BtnPrimary>
            </div>
        </header>

        <slot name="subtitle" />
    </div>
</template>
