<script>
import Pdf from "@/Pdf";
import debounce from "lodash/debounce";
import PdfSideHeader from "@/components/Global/PdfSideHeader.vue";
import { toRaw } from "vue";
import PdfToc from "./PdfToc.vue";

export default {
    components: { PdfSideHeader, PdfToc },
    props: {
        ebookPageData: {
            type: String,
            default: null,
        },
        isPreview: {
            type: Boolean,
            required: true,
        },
        isTeacherGuide: {
            type: Boolean,
            required: true,
        },
        user: {
            type: Object,
            default: null,
        },
        resource: {
            type: Object,
            default: null,
        },
        showPrevNext: {
            type: Boolean,
            default: true,
        },
        tableOfContents: {
            type: Array,
            default: () => [],
        },
        totalPages: {
            type: Number,
            required: true,
        },
        initPage: {
            type: [Number, String],
            default: 1,
        },
    },
    data() {
        return {
            canvasWrapperId: "pdf-canvas-wrapper",
            canvasId: "pdf-canvas",
            pdf: null,
            scale: 0,
            scalePercent: "0%",
            pageRendering: false,
            pageNumberPending: null,
            pageNumber: {
                type: [Number, String],
                default: 1,
            },
            showToc: true, // Table of content (toc)
            pageData: null,
            pageLoading: false,
        };
    },
    mounted() {
        if (this.ebookPageData) {
            this.showToc = this.isMobileAndTablet() || !!(this.tableOfContents && this.tableOfContents.length > 0); // Close if no toc

            this.pageNumber = this.initPage;

            this.loadPage(this.ebookPageData);
        }
    },
    methods: {
        prevPage: debounce(function () {
            // Add debounce to prevent multiple requests in a short period of time
            if (this.isPreview && this.resource.ebook_sample_pages) {
                const index = this.resource.ebook_sample_pages.indexOf(parseInt(this.pageNumber, 10));
                if (index > 0 && this.resource.ebook_sample_pages[index - 1]) {
                    this.queueLoadPage(parseInt(this.resource.ebook_sample_pages[index - 1], 10));
                }
            } else if (this.pageNumber > 1) {
                this.queueLoadPage(parseInt(this.pageNumber, 10) - 1);
            }
        }, 500),
        nextPage: debounce(function () {
            // Add debounce to prevent multiple requests in a short period of time
            if (this.isPreview && this.resource.ebook_sample_pages) {
                const index = this.resource.ebook_sample_pages.indexOf(parseInt(this.pageNumber, 10));
                if (index >= 0 && this.resource.ebook_sample_pages[index + 1]) {
                    this.queueLoadPage(parseInt(this.resource.ebook_sample_pages[index + 1], 10));
                }
            } else if (this.pageNumber < this.totalPages) {
                this.queueLoadPage(parseInt(this.pageNumber, 10) + 1);
            }
        }, 500),
        queueLoadPage(pageNumber) {
            if (typeof pageNumber === "string") {
                pageNumber = parseInt(pageNumber.replace(/\D/g, ""), 10);
                pageNumber = Number.isInteger(pageNumber) ? pageNumber : 1;
            }

            if (pageNumber <= 1) {
                pageNumber = 1;
            } else if (pageNumber > this.totalPages) {
                pageNumber = this.totalPages;
            }

            if (this.pageLoading) {
                this.pageNumberPending = pageNumber;
            } else {
                this.pageNumber = pageNumber;
                this.pageNumberPending = null;

                let route = window.route("resources.ebook-page-url", [this.resource.id]);
                if (this.isPreview) {
                    route = window.route("sample-test.ebook-page-url", [this.resource.id]);
                }

                const params = {
                    pageNumber: this.pageNumber,
                    isTeacherGuide: this.isTeacherGuide,
                };
                axios
                    .get(route, { params })
                    .then((response) => {
                        this.loadPage(null, response.data.presignedUrl);
                    })
                    .catch(({ response }) => {
                        console.log(response);
                        // If somehow user is still enable to get page that is out of bounds, let you know.
                        if (response && response?.status === 404) {
                            alert("Invalid request. Page not found!");
                        } else {
                            alert("Failed to fetch page. Please contact us for assistance.");
                        }
                    });
            }
        },
        loadPage(pageData = null, presignedUrl = null) {
            if (presignedUrl) {
                new Pdf(presignedUrl, null, false).init().then((pdf) => {
                    this.pdf = pdf;
                    this.renderPage();
                });
            } else {
                // Initialize the PDF, then set the pdf object
                new Pdf(null, pageData, false).init().then((pdf) => {
                    this.pdf = pdf;
                    this.renderPage();
                });
            }
        },
        renderPage() {
            // If user clicks next/prev page too fast, then make sure the page is done loading before trying to load in another page.
            if (this.pageLoading) {
                return;
            }
            this.pageLoading = true;

            toRaw(this.pdf)
                .getPage(1)
                .then((page) => {
                    if (this.scale <= 0) {
                        this.setDefaultCanvasScale(page);
                    }
                    const viewport = page.getViewport({ scale: this.scale });

                    // Prepare canvas using PDF page dimensions
                    const canvasWrapper = document.getElementById(this.canvasWrapperId);
                    const canvas = document.getElementById(this.canvasId);
                    const context = canvas.getContext("2d");
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Vertical center pdf canvas
                    this.centerCanvas();

                    // Render PDF page into canvas context
                    const renderContext = {
                        canvasContext: context,
                        viewport,
                    };

                    const renderTask = page.render(renderContext);
                    // Wait for rendering to finish
                    renderTask.promise
                        .then(() => {
                            this.pageLoading = false;

                            // Replaced the usage of vue-router to prevent console warning
                            const searchParams = new URLSearchParams();
                            // Page number should be the only query parameter
                            searchParams.set("page", this.pageNumber);
                            window.history.pushState(
                                null,
                                "",
                                `${window.location.pathname}?${searchParams.toString()}`,
                            );

                            // If there is page number pending, load and render page again.
                            if (this.pageNumberPending !== null) {
                                this.queueLoadPage(this.pageNumberPending);
                            }
                        })
                        .then(() =>
                            // Returns a promise, on resolving it will return text contents of the page
                            page.getTextContent(),
                        )
                        .then((textContent) => {
                            const textLayer = document.getElementById("text-layer");
                            // Clear HTML for text layer
                            textLayer.html = "";

                            // Render text on top of canvas
                            this.pdf.pdfjsLib.renderTextLayer({
                                textContent,
                                container: textLayer,
                                viewport,
                                textDivs: [],
                            });
                        });
                });
        },
        pageNumberChanged: debounce(function () {
            this.queueLoadPage(this.pageNumber);
        }, 750),
        zoomIn() {
            let val = Math.ceil(this.scale * 10) / 10; // Round up to the first decimal
            if (val == this.scale) {
                val += 0.1; // Increment by 10%
            }

            this.scalePercent = `${Math.floor(val * 100)}%`;
            this.updateScale();
        },
        zoomOut() {
            let val = Math.floor(this.scale * 10) / 10; // Round down to the first decimal
            if (val == this.scale) {
                val -= 0.1; // Decrement by 10%
            }

            this.scalePercent = `${Math.floor(val * 100)}%`;
            this.updateScale();
        },
        updateScale() {
            const val = this.scalePercent;
            let percent = parseInt(val.replace(/\D/g, ""), 10);
            percent = Number.isInteger(percent) ? parseInt(percent, 10) : 100;

            // Min zoom
            if (percent < 10) {
                percent = 10;
            } // Max zoom
            else if (percent > 500) {
                percent = 500;
            }

            this.scalePercent = `${percent}%`;
            this.scale = percent / 100;
            this.renderPage();
        },
        scalePercentChanged: debounce(function () {
            this.updateScale();
        }, 750),
        canvasBiggerThanWrapper() {
            const canvasWrapper = document.getElementById(this.canvasWrapperId);
            const canvas = document.getElementById(this.canvasId);
            const boxWrapper = canvasWrapper.getBoundingClientRect();
            const box = canvas.getBoundingClientRect();
            const marginY = 32; // 2rem
            const height = boxWrapper.height - box.height - marginY;

            if (height > 0) {
                return false;
            }
            return true;
        },
        centerCanvas() {
            const canvasIsBigger = this.canvasBiggerThanWrapper();
            const canvasWrapper = document.getElementById(this.canvasWrapperId);
            const canvas = document.getElementById(this.canvasId);
            const textLayer = document.getElementById("text-layer");
            if (canvasIsBigger) {
                // Remove vertical center styles
                canvasWrapper.style.display = null;
                canvasWrapper.style.alignItems = null;
                canvasWrapper.style.justifyContent = null;
                // Add y padding to canvas wrapper
                canvasWrapper.style.padding = "48px";
            } else {
                // Remove y padding to canvas wrapper
                canvasWrapper.style.padding = null;
                // Add vertical center styles
                canvasWrapper.style.display = "flex";
                canvasWrapper.style.alignItems = "center";
                canvasWrapper.style.justifyContent = "center";
            }

            textLayer.style.top = `${canvas.offsetTop}px`;
            textLayer.style.height = `${canvas.height}px`;
            textLayer.style.width = `${canvas.width}px`;
        },
        setDefaultCanvasScale(page) {
            const canvasWrapper = document.getElementById(this.canvasWrapperId);
            const box = canvasWrapper.getBoundingClientRect();
            const oneInch = 96; // pixel
            const pdf100 = 9; // Inch
            const height = box.height - 27 * 2;
            let percent = Math.floor(height / oneInch) / pdf100;
            percent = Math.floor(percent * 100) / 100; // Round down to the second decimal
            if (percent < 0) {
                percent = 1;
            }

            this.scale = percent;
            this.scalePercent = `${Math.floor(this.scale * 100)}%`;
        },
        isMobileAndTablet() {
            let check = false;
            (function (a) {
                if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                        a,
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                        a.substr(0, 4),
                    )
                )
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
    },
};
</script>

<template>
    <div
        v-if="pdf"
        id="pdf-viewer-wrapper"
        class="w-full bg-slate-400 print:hidden"
    >
        <!-- Top Toolbar-->
        <div
            class="fixed z-10 flex h-10 w-full overflow-hidden bg-slate-700 px-6 py-2 text-white shadow-md shadow-slate-800"
        >
            <div class="flex-1">
                <span
                    @click="showToc = !showToc"
                    class="mr-4 cursor-pointer"
                >
                    <i class="fas fa-bars" />
                </span>
                {{ resource.title }}
            </div>

            <div class="flex-1 select-none text-right">
                <span
                    class="mr-2"
                    @click="prevPage"
                >
                    <i class="fas fa-chevron-left cursor-pointer" />
                </span>

                <input
                    type="text"
                    v-model="pageNumber"
                    class="w-14 bg-slate-900 text-center"
                    :readonly="this.isPreview"
                    @click="$event.target.select()"
                    @input="pageNumberChanged"
                />
                of {{ totalPages }}
                <span
                    class="ml-2"
                    @click="nextPage"
                >
                    <i class="fas fa-chevron-right cursor-pointer" />
                </span>

                <span class="mx-4 text-slate-400">|</span>

                <span @click="zoomOut">
                    <i class="fas fa-minus cursor-pointer" />
                </span>

                <input
                    type="text"
                    v-model="scalePercent"
                    class="mx-2 w-14 bg-slate-900 text-center"
                    @click="$event.target.select()"
                    @input="scalePercentChanged"
                />

                <span @click="zoomIn">
                    <i class="fas fa-plus cursor-pointer" />
                </span>
            </div>
        </div>

        <div class="box-border flex h-full border-0 border-t-[2.5rem] border-white">
            <!-- Table of content -->
            <Transition name="slide">
                <div
                    v-show="showToc"
                    class="relative box-border h-full w-80 flex-none overflow-y-auto bg-slate-700 p-4 text-white"
                >
                    <PdfSideHeader :user="user"></PdfSideHeader>

                    <hr class="my-2 border" />

                    <PdfToc
                        v-if="tableOfContents && tableOfContents.length > 0"
                        :items="tableOfContents"
                        :is-preview="isPreview"
                        :sample-pages="resource.ebook_sample_pages"
                        @go-to-page="queueLoadPage"
                    />

                    <div v-else>No table of contents</div>
                </div>
            </Transition>
            <!-- PDF page -->
            <div
                :id="canvasWrapperId"
                class="relative box-border h-full flex-auto overflow-auto"
                :style="{ '--scale-factor': scale }"
            >
                <canvas
                    :id="canvasId"
                    class="mx-auto drop-shadow-[0_0_15px_rgba(0,0,0,0.50)]"
                ></canvas>

                <div
                    id="text-layer"
                    class="textLayer mx-auto"
                ></div>
            </div>
        </div>
    </div>
</template>

<style>
#pdf-viewer-wrapper {
    height: 100vh;
}

.slide-leave-active,
.slide-enter-active {
    transition: 1s;
}

.slide-enter {
    transform: translate(-100%, 0);
}

.slide-leave-to {
    transform: translate(-100%, 0);
}

#text-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;
}

#text-layer > div {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
}
</style>
