<script setup>
import CogSolid from "@/components/Icons/CogSolid.vue";
import cwLogoUrl from "@images/CourseWaveLogoWhite.svg";
import abcLogoUrl from "@images/ABC_Logo_No_Text.svg";
import axios from "axios";
import BtnPrimary from "./BtnPrimary.vue";

const props = defineProps({
    user: {
        type: [Object, null],
        default: null,
        required: false,
    },
});

function logout() {
    console.log("Logout");
    axios
        .post(window.route("logout"))
        .then((response) => {
            window.location.href = route("login");
        })
        .catch(({ response }) => {
            console.log(response);
        });
}
</script>

<template>
    <div>
        <div class="flex">
            <div class="w-44 flex-none">
                <a
                    :href="window.route('home')"
                    title="Home"
                >
                    <img
                        class="w-full"
                        :src="cwLogoUrl"
                        alt="CourseWave logo"
                    />
                </a>
            </div>

            <div class="relative w-10 flex-none">
                <a
                    :href="window.route('home')"
                    title="Home"
                    class="absolute bottom-2 left-3 text-sm font-light tracking-widest"
                >
                    <span class="text-lg md:text-xl">By</span>
                </a>
            </div>

            <div class="relative w-12 flex-none">
                <a
                    :href="window.route('home')"
                    title="Home"
                    class="absolute bottom-4"
                >
                    <img
                        class="w-full"
                        :src="abcLogoUrl"
                        alt="ABC logo"
                    />
                </a>
            </div>
        </div>

        <div class="flex justify-end gap-x-2 pb-2 pt-8">
            <BtnPrimary
                href="https://help.coursewave.com/"
                target="_blank"
            >
                <i class="fas fa-question fa-lg"></i>
            </BtnPrimary>

            <BtnPrimary
                v-if="user"
                :href="window.route('settings')"
            >
                <CogSolid class="h-5 w-5 fill-white" />
            </BtnPrimary>

            <BtnPrimary
                v-if="user && user.is_admin"
                href="/admin"
            >
                <i class="fas fa-users-cog fa-lg"></i>
            </BtnPrimary>

            <BtnPrimary
                v-if="user"
                @click="logout"
            >
                <i class="fas fa-sign-out-alt fa-lg"></i>
            </BtnPrimary>
        </div>
    </div>
</template>
