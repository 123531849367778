import type QuestionBlock from "@/types/Blocks/QuestionBlock";
import { cloneDeep } from "lodash";
import debounce from "lodash/debounce";
import { computed, onBeforeMount, provide, type Ref, ref, toRef } from "vue";
import { useStore } from "vuex";

export default function useQuestionBasics(props: QuestionBlock, defAnswer: Ref | string | array | object) {
    const defaultAnswer = toRef(defAnswer);
    const answer = ref(cloneDeep(defaultAnswer.value));
    const store = useStore();

    const clientId = computed(() => props.clientID ?? props.clientId);

    const questionNumber = computed(() => props.questionNumber);
    const correctAnswer = computed(() => store.getters.correctAnswer(clientId.value));
    const graded = computed(() => store.getters.graded);
    const question = computed(() => store.getters.question(clientId.value));
    const scoreRecieved = computed(() => store.getters.scoreRecieved(clientId.value));
    const submittedAnswer = computed(() => store.getters.submittedAnswer(clientId.value));
    const submittedAnswerValue = computed(() => store.getters.submittedAnswerValue(clientId.value));

    const valueIsCorrect = (value) => {
        if (Array.isArray(correctAnswer.value)) {
            if (typeof correctAnswer.value[0] === "number") {
                value = parseInt(value);
            }
            if (correctAnswer.value.includes(value)) {
                return true;
            }
        } else if (value == correctAnswer.value) {
            return true;
        }
        return false;
    };

    const answerState = computed(() => (value, bypassValueCheck = false) => {
        if (!graded.value || (/\d+-\d+/.test(value) && !bypassValueCheck)) {
            return "";
        }

        const submittedAnswer = submittedAnswerValue.value;
        console.log("--- Value:", value);
        console.log("Submitted Answer:", submittedAnswer);
        console.log("Correct Answer:", correctAnswer.value);
        if (!correctAnswer.value) {
            return "";
        }

        // No submitted answer
        if (!submittedAnswerValue.value) {
            if (valueIsCorrect(value)) {
                return "incorrect should-be-selected";
            }
            return "";
        }

        if (Array.isArray(correctAnswer.value)) {
            if (submittedAnswer.indexOf(value) > -1 && valueIsCorrect(value)) {
                return "correct";
            }
            if (submittedAnswer.indexOf(value) === -1 && !valueIsCorrect(value)) {
                return "";
            }
            if (submittedAnswer.indexOf(value) > -1 && !valueIsCorrect(value)) {
                return "incorrect";
            }
        } else {
            if (value == submittedAnswer && valueIsCorrect(value)) {
                return "correct";
            }
            if (value == submittedAnswer && !valueIsCorrect(value)) {
                return "incorrect";
            }
            if (value != submittedAnswer && !valueIsCorrect(value)) {
                return "";
            }
        }

        return "incorrect should-be-selected";
    });

    const resetAnswer = (event = null, skipReset = false) => {
        store
            .dispatch("resetAnswer", {
                clientId: clientId.value,
                default: defaultAnswer,
            })
            .then(() => {
                if (skipReset) {
                    return Promise.resolve();
                }

                let newAnswer;

                if (Array.isArray(defaultAnswer)) {
                    newAnswer = cloneDeep(defaultAnswer.value);
                } else if (typeof defaultAnswer.value === "object" && defaultAnswer.value !== null) {
                    newAnswer = cloneDeep(defaultAnswer.value);
                }

                if (!skipReset) {
                    answer.value = newAnswer;
                }

                Promise.resolve();
            });
    };

    const saveAnswer = debounce(() => {
        store.dispatch("saveAnswer", {
            clientId: clientId.value,
            answer: answer.value,
        });
    }, 200);

    onBeforeMount(() => {
        if (submittedAnswer.value?.answer) {
            answer.value = cloneDeep(submittedAnswer.value?.answer);
        }
    });

    provide("graded", graded);

    return {
        answer,
        clientId,
        correctAnswer,
        graded,
        question,
        questionNumber,
        resetAnswer,
        saveAnswer,
        scoreRecieved,
        submittedAnswer,
        submittedAnswerValue,
        valueIsCorrect,
        answerState,
    };
}
