<script setup lang="ts">
import PdfViewer from "@/components/Global/PdfViewer.vue";
import { Head, usePage } from "@inertiajs/vue3";

const props = defineProps({
    eBookPageData: {
        type: String,
        required: true,
    },
    isPreview: {
        type: Boolean,
        default: false,
    },
    isTeacherGuide: {
        type: Boolean,
        default: false,
    },
    pageNumber: {
        type: Number,
        required: true,
    },
    resource: {
        type: Object,
        required: true,
    },
    tableOfContents: {
        type: Array,
        required: true,
    },
    totalPages: {
        type: Number,
        required: true,
    },
});

const user = usePage().props?.auth?.user ?? {};
const headerTitle = `eBook - ${props.resource?.title}`;
</script>

<template>
    <div>
        <Head :title="headerTitle" />

        <PdfViewer
            v-if="eBookPageData"
            :ebook-page-data="eBookPageData.toString()"
            :init-page="pageNumber"
            :is-preview="isPreview"
            :is-teacher-guide="isTeacherGuide"
            :resource="resource"
            :table-of-contents="tableOfContents"
            :total-pages="totalPages"
            :user="user"
        />

        <div
            v-else-if="resource"
            class="pt-4 text-center"
        >
            The requested resource does not have page {{ pageNumber }}!
        </div>

        <div
            v-else
            class="pt-4 text-center"
        >
            No eBook found for this resource.
        </div>
    </div>
</template>

<style scoped></style>
