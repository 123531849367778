<script setup lang="ts">
import { PropType } from "vue";

const props = defineProps({
    errors: {
        type: Object as PropType<{ key: string; value: string }[]>,
        required: false,
    },
});
</script>

<template>
    <ul
        v-if="props.errors && Object.keys(props.errors).length > 0"
        class="border border-yellow-300 bg-yellow-100 px-4 py-2 text-sm text-red-500"
    >
        <li
            v-for="(error, key) in props.errors"
            :key="`error-${key}`"
            class="list-inside list-disc"
        >
            {{ error }}
        </li>
    </ul>
</template>
