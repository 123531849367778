<script>
import RedeemCodeBtn from "@/components/Global/RedeemCodeBtn.vue";

export default {
    components: { RedeemCodeBtn },
    methods: {
        refreshPage() {
            window.location.reload();
        },
    },
};
</script>

<template>
    <div class="relative md:float-right">
        <div class="text-right md:flex">
            <RedeemCodeBtn @redeemed="refreshPage"></RedeemCodeBtn>
        </div>
    </div>
</template>

<style scoped></style>
