<script>
import MasterResourceList from "@/components/Global/MasterResourceList.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

export default {
    components: { BtnPrimary, MasterResourceList },
    data() {
        return {
            includeArchived: false,
            resources: [],
        };
    },

    props: {
        initialResources: {},

        archived: {
            type: Boolean,
            default: false,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
    },

    methods: {},

    created() {
        this.resources = [...this.initialResources];
    },
};
</script>

<template>
    <MasterResourceList
        v-if="resources.length > 0"
        :refined-resources="resources"
        :hide-search="hideSearch"
        initial-order="resources_title_asc"
    >
        <template #row="{ resource, resourceCode }">
            <div class="w-48 p-2">
                <img
                    class="mx-auto h-auto max-h-40 sm:max-h-full"
                    :src="resource.image_url"
                    :alt="`Resource  ${resource.id}`"
                />
            </div>

            <div class="w-full border-0 p-2 md:w-6/12 md:border-l md:border-r lg:w-7/12 xl:w-9/12">
                <div class="card-body">
                    <span
                        v-if="resourceCode && resourceCode.days_left <= 30"
                        class="float-right -mr-2 bg-yellow-300 px-4 py-2 text-sm drop-shadow-lg"
                    >
                        <i class="fas fa-clock mr-1"></i>

                        <span v-if="resourceCode.days_left > 0">Expires in {{ resourceCode.days_left }} days</span>

                        <span v-else>Expired</span>
                    </span>

                    <h3 class="text-2xl">{{ resource.title }}</h3>

                    <p class="text-sm">Number of Tests: {{ resource.test_count }}</p>
                </div>
            </div>

            <div class="w-full p-2 md:w-3/12 lg:w-3/12 xl:w-2/12">
                <div
                    class="flex h-full w-full flex-col justify-center space-y-2 sm:w-1/4 sm:flex-row md:mx-auto md:w-1/2 md:flex-col"
                    v-cloak
                >
                    <BtnPrimary
                        type="primary-darker"
                        outline
                        pill
                        :href="route('resources.show', [resource])"
                    >
                        View
                    </BtnPrimary>
                </div>
            </div>
        </template>
    </MasterResourceList>

    <div
        v-else
        class="py-6 text-center"
    >
        Sorry, you currently have no resources.
    </div>
</template>
