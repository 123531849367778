<!-- Usage: <c-checkbox :label="'Foo'" :input-value="'foo'" v-model="states" /> -->

<script>
export default {
    name: "CCheckbox",
    emits: ["input", "update:modelValue"],
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Array, Boolean, Number],
            required: true,
        },
        inputValue: {
            type: [String, Boolean, Number],
            required: true,
        },
    },
    computed: {
        model: {
            get() {
                if (Array.isArray(this.modelValue)) {
                    return this.modelValue.includes(this.inputValue);
                }

                return this.modelValue;
            },

            set(value) {
                let newValue = this.modelValue;

                if (Array.isArray(this.modelValue)) {
                    if (value) {
                        newValue.push(this.inputValue);
                    } else {
                        const index = newValue.findIndex((v) => v === this.inputValue);

                        newValue.splice(index, 1);
                    }
                } else {
                    newValue = value;
                }

                this.$emit("update:modelValue", newValue);
            },
        },
    },
};
</script>

<template>
    <label>
        <input
            class="mr-2"
            type="checkbox"
            :value="inputValue"
            v-model="model"
        />

        <span
            class="font-light"
            v-html="label"
        ></span>
    </label>
</template>

<style scoped></style>
