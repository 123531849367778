<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
import { Head, useForm, usePage } from "@inertiajs/vue3";
import CText from "@/components/Form/CText.vue";
import LabelTag from "@/components/Form/LabelTag.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ArrowRightBold from "@/components/Icons/ArrowRightBold.vue";
import ListErrors from "@/components/Global/ListErrors.vue";

const props = defineProps({
    token: {
        type: String,
        required: true,
    },
    email: {
        type: String,
        required: true,
    },
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: "",
    password_confirmation: "",
    processing: false,
    errors: {},
});
const submit = async () => {
    form.processing = true;
    form.msg = "";
    form.clearErrors();
    form.post(route("password.update"), {
        onFinish: () => {},
        onSuccess: () => {
            form.processing = false;
            form.msg = "Email sent. Please check your inbox.";
        },
        onError: (errors) => {
            console.log(errors);
        },
    });
};
</script>

<template>
    <AppLayout>
        <Head title="Request Password" />

        <main
            class="relative bg-cover bg-center bg-no-repeat"
            style="background-image: url(/images/BgLogin.svg)"
            :style="'min-height:' + (window.innerHeight - 105) + 'px'"
        >
            <div class="mx-4 min-h-full py-10 md:mx-auto md:w-1/2 xl:w-1/3">
                <div class="mb-2 w-full text-center md:mb-10">
                    <img
                        class="mx-auto hidden w-72 sm:block"
                        src="/images/CourseWaveLogo.svg"
                        :alt="String(usePage().props.appName)"
                    />
                </div>

                <div class="overflow-hidden rounded border bg-white px-10 py-14 shadow-card">
                    <h1 class="mb-4 text-center text-3xl">Reset Password</h1>

                    <ListErrors
                        :errors="form.errors"
                        class="mb-2"
                    />

                    <form @submit.prevent="submit">
                        <div class="mb-4">
                            <LabelTag for="email">E-mail</LabelTag>

                            <CText
                                id="email"
                                v-model="form.email"
                                type="email"
                                placeholder="Enter your email"
                                required
                            ></CText>
                        </div>

                        <div class="mb-4">
                            <LabelTag for="password">New Password</LabelTag>

                            <CText
                                id="password"
                                v-model="form.password"
                                type="password"
                                placeholder="Enter your new password"
                                required
                            ></CText>
                        </div>

                        <div class="mb-4">
                            <LabelTag for="password_confirmation">Confirm Password</LabelTag>

                            <CText
                                id="password_confirmation"
                                v-model="form.password_confirmation"
                                type="password"
                                placeholder="Confirm your new password"
                                required
                            ></CText>
                        </div>

                        <div class="mt-6">
                            <BtnPrimary
                                class="w-full"
                                :disabled="form.processing"
                            >
                                Reset Password
                                <ArrowRightBold class="inline-block w-6" />
                            </BtnPrimary>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    </AppLayout>
</template>

<style scoped></style>
