<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import Review from "@/components/TestTaking/sidebar/Review.vue";
import Question from "@/components/TestTaking/sidebar/Question.vue";

const store = useStore();

const props = defineProps({
    page: {
        type: Object,
        required: true,
    },
});

const isSample = computed(() => store.state.isSample);
</script>

<template>
    <div :class="isSample && !page.is_sample ? 'opacity-50' : ''">
        <div class="my-4 flex">
            <div class="flex flex-shrink text-xs uppercase">Page {{ page.order }}</div>

            <div class="flex flex-grow items-center pl-3">
                <hr class="h-px w-full bg-gray-500" />
            </div>
        </div>

        <Review v-if="page.isReview"></Review>

        <Question
            :question="question"
            :page="page"
            v-for="question in page.questions"
            :key="question.id"
            v-else
        ></Question>
    </div>
</template>
