<script>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import CText from "@/components/Form/CText.vue";

export default {
    components: { BtnPrimary, CText },
    data() {
        return {
            recovery: false,
            form: {
                code: "",
                recovery_code: "",
                processing: false,
                error: "",
            },
            recoveryCodeInput: null,
            codeInput: null,
        };
    },
    methods: {
        async toggleRecovery() {
            this.recovery ^= true;
            await this.$nextTick();

            if (this.recovery) {
                this.form.code = "";
            } else {
                this.form.recovery_code = "";
            }
        },
        submit() {
            const data = {};
            if (!this.recovery) {
                data.code = this.form.code;
            } else {
                data.recovery_code = this.form.recovery_code;
            }

            this.form.processing = true;
            axios
                .post("/two-factor-challenge", data)
                .then((response) => {
                    window.location.href = "/home";
                })
                .catch((error) => {
                    console.log(error.response);
                    if (!this.recovery) {
                        this.form.error = error.response.data.errors.code[0];
                    } else {
                        this.form.error = error.response.data.errors.recovery_code[0];
                    }
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
    },
};
</script>

<template>
    <div class="card flex h-full flex-col items-stretch">
        <div class="flex flex-1 flex-col items-start justify-between">
            <h3 class="mb-4 text-4xl">Two Factor Authentication</h3>

            <div class="mb-4 w-full text-sm text-gray-600">
                <template v-if="!recovery">
                    Please confirm access to your account by entering the authentication code provided by your
                    authenticator application.
                </template>

                <template v-else>
                    Please confirm access to your account by entering one of your emergency recovery codes.
                </template>
            </div>

            <form @submit.prevent="submit" class="w-full">
                <CText
                    v-if="!recovery"
                    id="code"
                    ref="codeInput"
                    v-model="form.code"
                    label="Code"
                    placeholder="Enter code here..."
                    class="w-full"
                    autocomplete="one-time-code"
                    required
                />

                <CText
                    v-else
                    id="recovery_code"
                    ref="recoveryCodeInput"
                    v-model="form.recovery_code"
                    label="Recovery Code"
                    placeholder="Enter code here..."
                    class="w-full"
                    autocomplete="one-time-code"
                    required
                />

                <div>
                    <BtnPrimary
                        type="submit"
                        class="mt-4 w-full rounded-[0.25rem] py-2.5 text-base"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        Login <i class="fas fa-arrow-right ml-2" />
                    </BtnPrimary>

                    <button
                        type="button"
                        class="mt-2 cursor-pointer text-sm text-blue-500 underline hover:underline"
                        @click.prevent="toggleRecovery"
                    >
                        <template v-if="!recovery"> Use a recovery code </template>

                        <template v-else> Use an authentication code </template>
                    </button>
                </div>

                <p v-if="form.error" class="mt-4 text-center text-sm text-red-700">{{ form.error }}</p>
            </form>
        </div>
    </div>
</template>

<style scoped></style>
