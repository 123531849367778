<script>
import { mapActions, mapGetters } from "vuex";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

export default {
    components: { BtnPrimary },
    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            page: "pageNumber",
            numberOfPages: "numberOfPages",
            _getNextPage: "getNextPage",
            _graded: "graded",
            _hasPageBeenCompleted: "hasPageBeenCompleted",
            _isPreview: "isPreview",
            _isLastContentPage: "isLastContentPage",
            _isLastPage: "isLastPage",
            _isSaving: "saving",
            _navigating: "navigating",
            _testSubmitted: "testSubmitted",
        }),

        disablePrevious() {
            return (
                this._navigating || this.page === 1 || !this.hasPreviousPage || this._isSaving || this._testSubmitted
            );
        },

        disableNext() {
            return this._navigating || this._isLastPage || !this.hasNextPage || this._isSaving || this._testSubmitted;
        },

        hasNextPage() {
            return this._getNextPage(1);
        },

        hasPreviousPage() {
            return this._getNextPage(-1);
        },

        nextLabelPosition() {
            return this.disableNext ? "none" : "down";
        },

        previousLabelPosition() {
            return this.disablePrevious ? "none" : "down";
        },

        showNextButton() {
            return this._isPreview || this._graded || this._hasPageBeenCompleted;
        },
    },

    methods: {
        ...mapActions({
            _navigateToPage: "navigateToPage",
            _goToReview: "goToReview",
        }),

        nextPage() {
            if (this.disableNext) {
                return;
            }

            if (this._isLastContentPage) {
                return this._goToReview();
            }

            this._navigateToPage(this._getNextPage(1)).then(() => {
                this.scrollTestPageToTop();
            });
        },

        previousPage() {
            if (this.disablePrevious) {
                return;
            }

            this._navigateToPage(this._getNextPage(-1)).then(() => {
                this.scrollTestPageToTop();
            });
        },
        async scrollTestPageToTop() {
            const pageContainerElement = document.getElementById("test-page-container");
            console.log("scrolling", pageContainerElement);
            pageContainerElement.scrollTo(0, 0);
        },
    },
};
</script>

<template>
    <div class="flex h-full items-center">
        <div class="flex w-24 justify-end">
            <BtnPrimary
                disabled-color="disabled:bg-primary-darker"
                disabled-text-color="disabled:text-primary"
                disabled-hover-color="disabled:hover:bg-primary-darker"
                disabled-hover-text-color="disabled:hover:text-primary"
                :disabled="disablePrevious"
                class="h-50p w-60p"
                aria-label="Previous Page"
                :data-balloon-pos="previousLabelPosition"
                @click="previousPage"
            >
                <FontAwesomeIcon
                    icon="arrow-left"
                    size="lg"
                />
            </BtnPrimary>
        </div>

        <div class="hidden flex-1 justify-center xl:flex">Page {{ page }}</div>

        <div
            v-show="showNextButton"
            class="ml-2 w-24 xl:ml-0"
        >
            <BtnPrimary
                disabled-color="disabled:bg-primary-darker"
                disabled-text-color="disabled:text-primary"
                disabled-hover-color="disabled:hover:bg-primary-darker"
                disabled-hover-text-color="disabled:hover:text-primary"
                :disabled="disableNext"
                class="h-50p w-60p"
                aria-label="Next Page"
                :data-balloon-pos="nextLabelPosition"
                @click="nextPage"
            >
                <FontAwesomeIcon
                    icon="arrow-right"
                    size="lg"
                />
            </BtnPrimary>
        </div>

        <div
            v-show="!showNextButton"
            class="ml-2 w-24 xl:ml-0"
        />
    </div>
</template>
