<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import Page from "@/components/TestTaking/sidebar/Page.vue";

const props = defineProps({
    test: {
        type: Object,
        default: () => ({}),
    },
});

const store = useStore();

const pages = computed(() => store.state.pages);

function showViewEBook() {
    if (!window.location.href.toLowerCase().includes("samples") && this.test && this.test.ebook_page_number) {
        return true;
    }
    return false;
}
</script>

<template>
    <Transition name="slideLeft">
        <div class="flex-shrink flex-wrap overflow-y-auto border-r border-gray-500 px-4 pb-12">
            <div
                v-if="showViewEBook"
                class="mt-4 hover:text-primary-lighter"
            >
                <a
                    :href="route('resources.eBook', test.resource_id) + '?page=' + test.ebook_page_number"
                    target="_blank"
                    aria-label="Open in a new tab"
                >
                    <i class="fas fa-book mr-1"></i> View eBook
                </a>
            </div>

            <Page
                :page="page"
                v-for="page in pages"
                :key="page.id"
            ></Page>
        </div>
    </Transition>
</template>
