<template>
    <button class="my-4" @click="goToReview">
        Review my test
    </button>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        data() {
            return {};
        },

        computed: {
            ...mapGetters({
                _navigating: 'navigating'
            }),
        },

        methods: {
            ...mapActions({
                _goToReview: 'goToReview'
            }),

            goToReview() {
                if (this._navigating) {
                    return;
                }

                this._goToReview();
            }
        }
    };
</script>
