<script setup lang="ts">
const { route } = window;
</script>

<template>
    <a
        :href="route('auth.redirect', ['microsoft'])"
        class="group mb-1 block"
    >
        <div
            class="group inline-flex h-12 w-full items-center justify-center rounded border border-primary-lighter bg-white py-1 pr-2 text-primary-lighter hover:bg-primary-lighter hover:text-white"
        >
            <div class="inline-flex w-64 items-center justify-start">
                <div class="mr-4 p-2 group-hover:rounded-sm group-hover:bg-white">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                    >
                        <title>MS-SymbolLockup</title>

                        <rect
                            x="1"
                            y="1"
                            width="9"
                            height="9"
                            fill="#f25022"
                        />

                        <rect
                            x="1"
                            y="11"
                            width="9"
                            height="9"
                            fill="#00a4ef"
                        />

                        <rect
                            x="11"
                            y="1"
                            width="9"
                            height="9"
                            fill="#7fba00"
                        />

                        <rect
                            x="11"
                            y="11"
                            width="9"
                            height="9"
                            fill="#ffb900"
                        />
                    </svg>
                </div>

                <span>Sign in with Microsoft</span>
            </div>
        </div>
    </a>
</template>
