<script setup lang="ts">
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import CText from "@/components/Form/CText.vue";
import CCheckbox from "@/components/Form/CCheckbox.vue";
import ArrowRightBold from "@/components/Icons/ArrowRightBold.vue";
import { useForm } from "@inertiajs/vue3";
import ListErrors from "@/components/Global/ListErrors.vue";

const form = useForm({
    email: "",
    password: "",
    remember: false,
    processing: false,
    errors: {},
});
const submit = async () => {
    form.processing = true;
    form.clearErrors();
    form.post(route("login"), {
        onFinish: () => {},
        onSuccess: () => {
            form.processing = false;
        },
        onError: (errors) => {
            console.log(errors);
        },
    });
};
</script>

<template>
    <form
        @submit.prevent="submit"
        class="mb-4 w-full px-8 pb-8"
    >
        <h3 class="mb-4 text-2xl">Using your username and password</h3>

        <ListErrors
            :errors="form.errors"
            class="mb-2"
        />

        <div class="form-group row">
            <div class="col-md-6">
                <CText
                    v-model="form.email"
                    type="email"
                    placeholder="Enter your email"
                    autocomplete="on"
                    autofocus
                    required
                ></CText>
            </div>
        </div>

        <div class="form-group row mt-4">
            <div class="">
                <CText
                    v-model="form.password"
                    type="password"
                    placeholder="Enter your password"
                    autocomplete="on"
                    required
                ></CText>
            </div>
        </div>

        <div class="my-4 md:flex md:items-center">
            <label class="block">
                <CCheckbox
                    v-model="form.remember"
                    input-value="1"
                    label="Remember Me"
                />

                <p class="pl-6 text-xs text-gray-700">Not recommended for public or shared computers}</p>
            </label>
        </div>

        <div class="flex flex-wrap items-center justify-between">
            <BtnPrimary
                class="w-full"
                :disabled="form.processing"
            >
                Login
                <ArrowRightBold class="inline-block w-6" />
            </BtnPrimary>

            <div class="mt-2 grid grid-cols-1 gap-1">
                <a
                    :href="route('password.request')"
                    class="text-sm text-primary-lighter hover:underline"
                >
                    Forgot Your Password?
                </a>

                <a
                    :href="route('register')"
                    class="text-sm text-primary-lighter hover:underline"
                >
                    Are you a teacher? Create an account here!
                </a>
            </div>
        </div>
    </form>
</template>
