<script setup lang="ts">
import CText from "@/components/Form/CText.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ArrowRightBold from "@/components/Icons/ArrowRightBold.vue";
import { useForm } from "@inertiajs/vue3";
import ListErrors from "@/components/Global/ListErrors.vue";

const props = defineProps({
    code: {
        type: String,
        required: false,
    },
});

const form = useForm({
    code: props.code ?? "",
    processing: false,
    errors: {},
});

const submit = async () => {
    form.processing = true;
    form.clearErrors();
    form.post(route("resource-login-post"), {
        onFinish: () => {},
        onSuccess: () => {
            form.processing = false;
        },
        onError: (errors) => {
            console.log(errors);
            form.errors.codeHelp = "Please contact your teacher for assistant.";
        },
    });
};
</script>

<template>
    <form
        @submit.prevent="submit"
        class="mb-4 w-full px-8 pb-8 pt-6"
    >
        <h3 class="mb-4 text-2xl">Using your code</h3>

        <ListErrors
            :errors="form.errors"
            class="mb-2"
        />

        <div class="mb-4 w-full">
            <CText
                v-model="form.code"
                placeholder="Enter your code"
                required
            />
        </div>

        <div class="flex items-center justify-between">
            <BtnPrimary
                class="w-full"
                :disabled="form.processing"
            >
                View Resource
                <ArrowRightBold class="inline-block w-6" />
            </BtnPrimary>
        </div>
    </form>
</template>
