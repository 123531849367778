<script setup lang="ts">
import { useStore } from "vuex";
import { computed } from "vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

const store = useStore();
const isSidebarOpen = computed(() => store.getters.isSidebarOpen);
const toggleSidebar = () => store.commit("toggleSidebar");
</script>

<template>
    <div class="mr-3 w-42">
        <BtnPrimary
            @click="toggleSidebar"
            class="mr-3 h-50p text-start"
            v-cloak
        >
            <FontAwesomeIcon
                :icon="!isSidebarOpen ? 'bars' : 'times'"
                size="lg"
                class="xl:mr-2"
            ></FontAwesomeIcon>

            <span class="hidden text-sm xl:inline">{{ isSidebarOpen ? "Close List" : "Question List" }}</span>
        </BtnPrimary>
    </div>
</template>
