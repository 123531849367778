<script setup>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import BookSolid from "@/components/Icons/BookSolid.vue";
import SignOut from "@/components/Icons/SignOut.vue";
import CogSolid from "@/components/Icons/CogSolid.vue";
import QuestionMark from "@/components/Icons/QuestionMark.vue";
import UserGear from "@/components/Icons/UserGear.vue";
import { useForm, usePage } from "@inertiajs/vue3";

const handleLogout = () => {
    const form = useForm({});
    form.post(route("logout"), {
        onError: (errors) => {
            console.log(errors);
        },
    });
};

const switchBook = () => {
    const form = useForm({});
    form.post(route("student-logout"), {
        onError: (errors) => {
            console.log(errors);
        },
    });
};
const testProps = usePage().props;
</script>

<template>
    <div class="flex flex-none items-center gap-2">
        <div class="hidden text-right lg:inline-block">{{ $page.props.auth.user.display_name }}</div>

        <BtnPrimary
            href="https://help.coursewave.com/"
            class="w-12"
            label="Help"
            label-position="down"
            target="_blank"
            type="link"
        >
            <QuestionMark class="h-6 w-6 fill-white" />
        </BtnPrimary>

        <BtnPrimary
            v-if="$page.props.auth.user && $page.props.auth.user.name"
            :href="route('settings')"
            class=""
            label="Settings"
            label-position="down"
            type="link"
        >
            <CogSolid class="h-6 w-6 fill-white" />
        </BtnPrimary>

        <BtnPrimary
            v-if="$page.props.auth?.resource"
            @click="switchBook"
            class="pr-2"
        >
            <BookSolid class="inline-block h-6 w-6 fill-white" />

            <span class="ml-2 hidden lg:inline">Switch Books</span>
        </BtnPrimary>

        <BtnPrimary
            v-if="$page.props.auth.user.is_admin"
            href="/admin"
            class=""
            label="Admin Area"
            label-position="down"
        >
            <UserGear class="h-6 w-6 fill-white" />
        </BtnPrimary>

        <BtnPrimary
            id="btnLogout"
            @click="handleLogout"
            type="submit"
        >
            <span class="flex gap-x-2">
                <SignOut class="h-6 w-6 fill-white" />

                <span class="ml-1 hidden lg:inline-block">Log Out</span></span
            >
        </BtnPrimary>
    </div>
</template>
